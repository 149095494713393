import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import parse from 'html-react-parser'
import './styles.scss'

const ContentDisclaimer = loadable(() => import('/src/components/Basic/ContentDisclaimer'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))

const Table = ({ backgroundColour, titleArea, tablepress, disclaimer }) => {
    return (
        <Section variant={backgroundColour} titleArea={titleArea} className={'c-table-block'}>
            {tablepress && parse(tablepress)}

            {disclaimer && (
                <div className="c-table-block__disclaimer">
                    <ContentDisclaimer content={disclaimer} />
                </div>
            )}
        </Section>
    )
}

Table.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']),
    titleArea: PropTypes.shape({
        addTitleArea: PropTypes.bool,
        titlePosition: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string
    }),
    tablepress: PropTypes.string,
    disclaimer: PropTypes.string
}

Table.defaultProps = {
    backgroundColour: 'alt',
    titleArea: {
        addTitleArea: true,
        titlePosition: 'centered',
        title: 'Section Title'
    },
    tablepress: `[table goes here]`,
    disclaimer:
        '<p>The figures in the Over 50s life cover comparison table are for a 60-year-old non-smoker, whose premium is £20 a month. This table is designed to compare levels of covee that may be available. It is not a comprehensive comparison of all product benefits.</p>'
}

export default Table

export const query = graphql`
    fragment TableForPageBuilder on WpPage_Acfpagebuilder_Layouts_Table {
        fieldGroupName
        backgroundColour
        addTitleArea
        title
        description
        tablepress
        disclaimer
    }
`
